import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue'),
    redirect: 'combo',
    children: [
      {
        path: 'index',
        name: '首页',
        component: () => import('../views/child/index.vue'),
      },
      {
        path: 'combo',
        name: '套餐管理',
        component: () => import('../views/child/combo.vue'),
      },
      {
        path: 'store',
        name: '商城管理',
        component: () => import('../views/child/store.vue'),
      },
      {
        path: 'order',
        name: '订单管理',
        component: () => import('../views/child/order.vue'),
      },
      {
        path: 'game',
        name: '游戏管理',
        component: () => import('../views/child/game.vue'),
      },
      {
        path: 'gameVideo',
        name: '实拍视频',
        component: () => import('../views/child/gameVideo.vue'),
      },
      {
        path: 'gameRoom',
        name: '游戏厅管理',
        component: () => import('../views/child/gameRoom.vue'),
      },
      {
        path: 'player',
        name: '玩家管理',
        component: () => import('../views/child/player.vue'),
      },
      {
        path: 'temporaryPlayer',
        name: '临时玩家管理',
        component: () => import('../views/child/temporaryPlayer.vue'),
      },
      {
        path: 'integral',
        name: '积分等级',
        component: () => import('../views/child/integral.vue'),
      },
      {
        path: 'subscribe',
        name: '现场预约',
        component: () => import('../views/child/subscribe.vue'),
      },
      // {
      //   path: 'printer',
      //   name: '打印机管理',
      //   component: () => import('../views/child/printer.vue'),
      // },
      // {
      //   path: 'gameMachine',
      //   name: '游戏机管理',
      //   component: () => import('../views/child/gameMachine.vue'),
      // },
      {
        path: 'ranking',
        name: '排行榜管理',
        component: () => import('../views/child/ranking.vue'),
      },
    ]
  },
  {
    path: '/loginUser',
    name: 'loginUser',
    component: () => import('../views/loginUser.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, form, next) => {
  if (to.path == '/loginUser') {
    next()
  } else {
    let token = localStorage.getItem('token') || ''
    if (token != '') {
      next()
    } else {
      next({ path: '/loginUser' })
    }
  }

})

export default router
