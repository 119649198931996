import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import mitt from 'mitt'


import moment from 'moment';


Vue.filter('format', function (value) {
  var date = value instanceof Date ? value : new Date(value);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();

  // 月份和日期补零
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;

  // 时、分、秒补零
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
});
Vue.config.productionTip = false

Vue.use(ElementUI)

Vue.prototype.$bus = new mitt()
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
